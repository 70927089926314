<template>
<div>

    <v-container>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <s-toolbar :color="'#244093'" dark label="Reporte de Ventas RE" class="tran"></s-toolbar>
                    <v-card>

                        <v-row justify="center" style="margin-top: 4px; margin-left: 10px; margin: auto">
                            <v-col cols="4" lg="4" md="4">
                                <s-select-definition :def="1131" v-model="objMounth" return-object label="Seleccione mes"></s-select-definition>
                            </v-col>
                            <v-col cols="4" lg="4" md="4">
                                <s-text label="Ingrese año" number v-model="txtYear"></s-text>
                            </v-col>
                            <v-col cols="4" lg="2" md="2" class="mt-4 pl-0">
                                <v-btn width="100%" rounded :color="'info'" small @click="run()">Buscar</v-btn>
                                <!-- <v-btn rounded :color="'error'" small @click="downloadReport()">Descargar</v-btn> -->
                            </v-col>
                            <v-col cols="4" lg="2" md="2" class="mt-4 pl-0">
                                <v-spacer></v-spacer>
                                <v-btn @click="show = !show" rounded :color="'warning'" small>
                                    <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>Descargar ° Ver
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>

            <v-card flat>
                <v-expand-transition>
                    <div v-show="show">
                        <v-divider></v-divider>
                        <v-row justify="center" style="">

                            <v-col cols="12" lg="12" md="12" class="mt-3 ml-5">
                                <v-btn outlined rounded :color="'error'" small @click="downloadOne()">Un Solo .xls</v-btn>
                                <v-btn outlined rounded :color="'error'" small @click="downloadAll()">Varios .xls</v-btn>

                                <!-- <v-btn outlined rounded :color="'info'" small @click="viewDasboardCV(1)">Visualizaciòn Grafica CV</v-btn> -->
                                <!-- <v-btn outlined rounded :color="'success'" small @click="viewDasboardCV(2)">Visualizaciòn Grafica RE</v-btn> -->
                            </v-col>

                        </v-row>

                    </div>
                </v-expand-transition>

            </v-card>

            <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
                <v-card color="primary" dark>
                    <v-card-text v-if="messageProcessing.length == 0">
                        Por favor espere
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                    <v-card-text v-else>
                        <div class="text--white pt-4">
                            Error al realizar busqueda <br />
                            {{ messageProcessing }}
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <!-- <v-dialog v-if="openModalGraph" v-model="openModalGraph" persistent hide-overlay width="100%">
                <v-card>
                    <v-row style="margin: auto">
                        <h3 class="mt-3 ml-6"></h3>
                        <v-spacer> </v-spacer>
                        <v-btn @click="openModalGraph = false" class="mt-3 mr-3" small fab color="error"><i style="font-size: 16px" class="fas fa-times"></i></v-btn>
                    </v-row>

                    <v-row style="margin-top: 0px;margin:auto">
                        <v-col cols="12">
                            <v-card class="mx-auto my-1" width="95%">
                                <v-row style="margin:auto">
                                    <v-col cols="6">
                                        <clc-sales-card-line-chart :itemsX="itemsX" :itemsY="itemsY"></clc-sales-card-line-chart>
                                    </v-col>
                                    <v-col cols="6">
                                        <clc-sales-card-line-chart-total :itemsX="itemsX1" :itemsY="itemsY1"></clc-sales-card-line-chart-total>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-dialog> -->

        </v-container>
        <v-container>
            <v-tabs v-model="currentItem">
                 <v-tab href="#Dashboard"> Dashboard</v-tab>
                <v-tab href="#SalesCV"> Reporte de Ventas CV</v-tab>
                <v-tab href="#SalesRE"> Reporte de Ventas RE</v-tab>
                <v-tab href="#SalesCVINFO"> Reporte de Ventas CV Info.</v-tab>
                <v-tab href="#SalesREINFO"> Reporte de Ventas RE Info.</v-tab>
            </v-tabs>

            <v-tabs-items v-model="currentItem">
                <v-tab-item :value="'Dashboard'">
                    <clc-sales-dasboard-detail 
                        :items="dataDashboardCv1"
                        :Month="txtMonth"
                    />
                </v-tab-item>
                <v-tab-item :value="'SalesCV'">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-data-table group-by="SlsQuadrant" :height="itemsCV.length > 0 ? '350px': 'auto'" :headers="headersCV" :items="itemsCV" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 100],
                    }">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>

                <v-tab-item :value="'SalesRE'">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-data-table group-by="SlsQuadrant" :height="'auto'" :headers="headersCV" :items="itemsRE" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 100],
                    }">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>
                <v-tab-item :value="'SalesCVINFO'">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :height="'auto'" :headers="headersInfo" :items="itemsCVI" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 100],
                    }">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>
                <v-tab-item :value="'SalesREINFO'">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :height="'auto'" :headers="headersInfo" :items="itemsREI" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 100],
                    }">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>

                <!--  <v-tab-item :value="'Dashboard'">
                    <clc-sales-detail-dashboard :dataDashboard="dataDashboard"></clc-sales-detail-dashboard>
                </v-tab-item> -->
            </v-tabs-items>
        </v-container>
    </v-container>
</div>
</template>

<script>
import _sClcService from "@/services/ReportClicks/ClcChargeMasive.js";
import * as XLSX from 'xlsx';

/* import ClcSalesCardLineChart from './Dashboard/ClcSalesCardLineChart.vue';
import ClcSalesCardLineChartTotal from './Dashboard/ClcSalesCardLineChartTotal.vue'; */

import ClcSalesDasboardDetail from './Dashboard/ClcSalesDasboardDetail.vue';

export default {
    components: {
/*         ClcSalesCardLineChart,
        ClcSalesCardLineChartTotal, */
        ClcSalesDasboardDetail
    },

    data() {
        return {
            currentItem: "tab-Funciones",
            processing: false,
            dialogShow: false,
            messageProcessing: "",
            objMounth: null,
            txtYear: this.$fun.getYear(),
            report: {},

            itemsCV: [],
            itemsRE: [],
            itemsREI: [],
            itemsCVI: [],
            headersCV: [{
                    text: "Cuadrante",
                    value: "SlsQuadrant",
                    width: "120"
                },
                {
                    text: "Lote anterior",
                    value: "SlsPreviousBacth",
                    width: "120"
                },
                {
                    text: "Lote nuevo",
                    value: "SlsBacthNew",
                    width: "120"
                },
                {
                    text: "Cliente",
                    value: "SlsCustomer",
                    width: "250"
                },
                {
                    text: "Documento",
                    value: "SlsDocument",
                    width: "120"
                },
                {
                    text: "Proyecto",
                    value: "SlsProject",
                    width: "350"
                },
                {
                    text: "Año",
                    value: "SlsYear",
                    width: "120"
                },
                {
                    text: "Mes",
                    value: "SlsMonth",
                    width: "120"
                },
                {
                    text: "C. Lote",
                    value: "SlsCodUnique",
                    width: "100"
                },
                {
                    text: "Area",
                    value: "SlsArea",
                    width: "120"
                },
                {
                    text: "Total",
                    value: "SlsTotal",
                    width: "120"
                },

            ],
            headersInfo: [{
                    text: "Cuadrante",
                    value: "SlsQuadrant"
                },
                {
                    text: "Proyecto",
                    value: "SlsProject"
                },
                {
                    text: "Etapa",
                    value: "SlsStage"
                },
                {
                    text: "Cant. Lotes",
                    value: "SlsCantBach"
                },
                {
                    text: "Area",
                    value: "SlsArea"
                },
                {
                    text: "Total",
                    value: "SlsTotal"
                }
            ],

            groupCv: [],
            groupRe: [],
            dataDashboard: [],

            show: false,
            openModalGraph: false,
            dataDashboardCv1: [],
            dataDashboardCv2: [],

            itemsX: [],
            itemsY: [],
            itemsX1: [],
            itemsY2: [],
            txtMonth: null
            /* openModalGraphRE: false, */
        }

    },

    methods: {

        run() {
            var filter = {
                SalesMonth: this.objMounth.DedDescription,
                SalesYear: this.txtYear
            }

            this.txtMonth = this.objMounth.DedDescription

            this.dataDashboardCv1 = []
            this.dataDashboardCv2 = []

            this.messageProcessing = ""
            this.processing = true;
            _sClcService.listDetail(filter, this.$fun.getUserID()).then(resp => {
                    if (resp.status == 200) {

                        this.processing = false;

                        this.itemsCV = resp.data.detailCV
                        this.itemsRE = resp.data.detailRE
                        this.itemsREI = resp.data.infoRE
                        this.itemsCVI = resp.data.infoCV

                        this.groupCv = _.groupBy(resp.data.detailCV.filter(x => x.SlsProject !== null), "SlsQuadrant");

                        var i = 0,
                            objTmp = {},
                            objTmp2 = {},
                            sumTotal = 0


                        for (let prop in this.groupCv) {

                            i = 0
                            objTmp = {}
                            objTmp2 = {}
                            sumTotal =0

                            this.groupCv[prop].forEach(element => {
                                i = i + 1;
                                sumTotal = sumTotal + element.SlsTotal
                            });

                            /* objTmp.Project = prop
                            objTmp.Count = i */
/*  */
                            objTmp.name = prop
                            objTmp.y = sumTotal
                            objTmp.z = i

                            this.dataDashboardCv1.push(objTmp)



                           /*  objTmp.Project = prop
                            objTmp.Count = i

                            objTmp2.Project = prop
                            objTmp2.Total = sumTotal

                            this.dataDashboardCv1.push(objTmp)
                            this.dataDashboardCv2.push(objTmp2) */
                        }

                       /*  this.itemsX = []
                        this.itemsY = []
                        this.dataDashboardCv1.forEach(element => {
                            this.itemsX.push(element.Project)
                            this.itemsY.push(element.Count)
                        });

                        this.itemsX1 = []
                        this.itemsY1 = []
                        this.dataDashboardCv2.forEach(element => {
                            this.itemsX1.push(element.Project)
                            this.itemsY1.push(element.Total)
                        });

                        console.log(this.dataDashboardCv2) */

                        return

                        this.groupCv = _.groupBy(resp.data.detailCV.filter(x => x.SlsProject !== null), "SlsProject");
                        this.groupRe = _.groupBy(resp.data.detailRE.filter(x => x.SlsProject !== null), "SlsProject");

                        var sum = 0,
                            sumRe = 0
                        var objTmp = {},
                            objTmp2 = {}

                        var data1 = [],
                            data2 = []

                        for (let prop in this.groupCv) {
                            objTmp = {}
                            this.groupCv[prop].map((i) => {
                                sum = sum + i.SlsTotal
                            });
                            objTmp.Project = prop;
                            objTmp.TotalCv = sum
                            //
                            data1.push(objTmp)
                        }

                        for (let prop in this.groupRe) {
                            objTmp2 = {}
                            this.groupRe[prop].map((i) => {
                                sumRe = sumRe + i.SlsTotal
                            });

                            objTmp2.Project = prop;
                            objTmp2.TotalRe = sumRe
                            data2.push(objTmp2)

                        }

                        var obj = [...data1, ...data2]
                        var newGroup = []
                        newGroup = _.groupBy(obj, "Project");

                        this.dataDashboard.push(newGroup)

                    }
                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = true;
                })
        },

        downloadOne() {
            const workSheet1 = XLSX.utils.json_to_sheet(this.itemsCV);
            const workSheet2 = XLSX.utils.json_to_sheet(this.itemsRE);
            const workSheet3 = XLSX.utils.json_to_sheet(this.itemsCVI);
            const workSheet4 = XLSX.utils.json_to_sheet(this.itemsREI);

            const workBook = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(workBook, workSheet1, "Ventas CV");
            XLSX.utils.book_append_sheet(workBook, workSheet2, "Ventas RE");
            XLSX.utils.book_append_sheet(workBook, workSheet3, "Ventas Info CV");
            XLSX.utils.book_append_sheet(workBook, workSheet4, "Ventas Info RE");

            //buffer
            let buffer = XLSX.write(workBook, {
                bookType: "xlsx",
                type: "buffer"
            });

            //binary
            XLSX.write(workBook, {
                bookType: "xlsx",
                type: "binary"
            });
            //donwload
            XLSX.writeFile(workBook, "ReporteVentasDetalle.xlsx");

        },

        viewDasboardCV(item) {
            if (item == 1) {
                this.openModalGraph = true
            } else {
                /* this.openModalGraphRE = true */
            }

        },

        downloadAll(){
            alert('No disponible')
        }

    }
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
